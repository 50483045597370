import { useState, useEffect } from "react";
import { Stack, Card, CardContent, Typography, Divider } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getInterviews } from "../../redux/actions/jobs";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils";

export const Interviews = ({ getInterviews, interviewList }) => {
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  const columns = [
    {
      field: "jb_name",
      headerName: "Job Title",
      flex: 1,
      minWidth: 330,
      renderCell: (params) => (
        <Link to={`/hr/jd-detail/${params.row.jb_id}`}>
          {params.row.jb_name}
        </Link>
      ),
    },
    { field: "batches", headerName: "Batches", flex : 1, },
    { field: "applicantsInvited", headerName: "Invite Sent", flex : 1, },
    { field: "applicantsCompleted", headerName: "Completed", flex : 1, },
    {
      field: "createdDate",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.createdDate
            ? formatDate(JSON.parse(localStorage.getItem("user")), params.row.createdDate)
            : ""}
        </>
      ),
    },
  ];

  useEffect(() => {
    getInterviews(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals]);
  useEffect(() => {
    updateTableHeight(interviewList?.rows?.length);
  }, [paginationModals.pageSize, interviewList]);
  return (
    <>
      <Card
        raised
        sx={{
          minWidth: "100%",
        }}
      >
        <CardContent>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Interviews</Typography>
            <Typography
              sx={{ fontSize: 12 }}
              gutterBottom
              textAlign="right"
              variant="h4"
            ></Typography>
          </Stack>
          <Divider />
          <div style={{ height: tableHeight, width: '100%' }}>
          <ServerPaginationGridNoRowCount
            setPaginationModals={setPaginationModals}
            paginationModals={paginationModals}
            rowsData={
              interviewList &&
              interviewList.rows &&
              interviewList.rows.length > 0
                ? interviewList.rows
                : []
            }
            total={
              interviewList && interviewList.count ? interviewList.count : 0
            }
            columns={columns}
          />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  interviewList: state.jobs.interviewList,
});

const mapDispatchToProps = { getInterviews };

export default connect(mapStateToProps, mapDispatchToProps)(Interviews);
