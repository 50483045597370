import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import loader from "./loader";
import dashboard from "./dashboard";
import jobs from "./jobs";
import applicant from "./applicant";
import candidate from "./candidate";
import batches from "./batches";
import skills from "./skills"
import progress from "./progress";
import employee from "./employee";
import hr from "./hr"
import sidebar from "./sidebar";

export default combineReducers({
  auth,
  message,
  loader,
  dashboard,
  jobs,
  applicant,
  candidate,
  batches,
  skills,
  progress,
  employee,
  hr,
  sidebar
});
