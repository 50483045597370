import {
  GET_ALL_LIST_JOB_SUCCESS,
  GET_JOB_SUCCESS,
  GET_JOBS_SUCCESS,
  APPLY_JOB_SUCCESS,
  CREATE_JOB_SUCCESS,
  DELETE_JOB_SUCCESS,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_STATUS_SUCCESS,
  UPLOAD_CVS_JOB_SUCCESS,
  UPDATE_JOB_SKILLS_SUCCESS,
  CLEAR_ADD_JOB_DATA,
  GET_BATCHES_SUCCESS,
  GET_CANDIDATES_SUCCESS,
  GET_HR_DASH_DATA_SUCCESS,
  PARSE_JD_SUCCESS,
  GET_INTERVIEWS_SUCCESS,
  GET_EMPLOYEES_SUCCESS,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_LIST_COURSES,
  CREATE_AUDIO_VIDEO,
  GET_JD_BATCHES_SUCCESS,
  CLEAR_PARSED_JD,
  CLEAR_CREATED_JD,
  CLEAR_BATCH_DATA,
  GET_SKILL_SUCCESS,
} from "./types";
import {
  _getAllList,
  _getJobById,
  _applyJob,
  _createJob,
  _updateJob,
  _deleteJob,
  _updateJobStatus,
  _getAll,
  _updateJobSkills,
  _uploadCVs,
  _getAllBatches,
  _getHrData,
  _parseJD,
  _getEmployees,
  _getInterviews,
  _getAllJobs,
  _getCourseList,
  _createAudioVideo,
  _getSkill,
} from "../../services/jobs.service";
import { showLoading, hideLoading } from "./loader";
import { setMessage } from "./message";

// export const getHrData = () => async (dispatch) => {
//   try {
//     // console.log("getHrData");
//     dispatch(showLoading());
//     const response = await _getHrData();

//     if (response.status === 200) {
//       dispatch({
//         type: GET_HR_DASH_DATA_SUCCESS,
//         payload: response.data,
//       });
//     }
//     dispatch(hideLoading());
//   } catch (error) {
//     console.error("error in getting data", error);
//     dispatch(hideLoading());
//   }
// };

export const parseJD = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _parseJD(data);
    if (response.status === 200) {
      dispatch({
        type: PARSE_JD_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in parsing JD", error);
    dispatch(hideLoading());
  }
};

export const clearJDData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PARSED_JD,
  });
};
export const clearCreatedJD = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CREATED_JD,
  });
};

export const getAllList = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getAllList();

    if (response.status === 200) {
      dispatch({
        type: GET_ALL_LIST_JOB_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting list data", error);
    dispatch(hideLoading());
  }
};

export const getSkill = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getSkill(id);

    if (response.status === 200) {
      dispatch({
        type: GET_SKILL_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting list data", error);
    dispatch(hideLoading());
  }
};

export const getJobsAll = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getAllJobs();

    if (response.status === 200) {
      dispatch({
        type: GET_ALL_JOBS_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting jobs", error);
    dispatch(hideLoading());
  }
};

export const getJobs = (page, size) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getAll(page, size);

    if (response.status === 200) {
      dispatch({
        type: GET_JOBS_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting jobs", error);
    dispatch(hideLoading());
  }
};

export const getInterviews = (page, size) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getInterviews(page, size);

    if (response.status === 200) {
      dispatch({
        type: GET_INTERVIEWS_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting interviews", error);
    dispatch(hideLoading());
  }
};
export const getEmployees = (page, size) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getEmployees(page, size);
    console.log(response);

    if (response.status === 200) {
      dispatch({
        type: GET_EMPLOYEES_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting employees", error);
    dispatch(hideLoading());
  }
};



export const getJob = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getJobById(id);

    if (response.status === 200) {
      dispatch({
        type: GET_JOB_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting job", error);
    dispatch(hideLoading());
  }
};

export const applyJob = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _applyJob(data);

    if (response.status === 200) {
      dispatch({
        type: APPLY_JOB_SUCCESS,
        payload: response.data,
      });
      dispatch(setMessage("Job applied successfully", "success"));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in applying job", error);
    dispatch(hideLoading());
    dispatch(
      setMessage("There is an issue applying job, please try again!", "error")
    );
  }
};

export const createJob = (data, cvs) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _createJob(data);

    dispatch({
      type: CLEAR_BATCH_DATA,
    })

    if (response.status === 200) {
      dispatch({
        type: CREATE_JOB_SUCCESS,
        payload: response.data,
      });
      // dispatch(getJobs());
      dispatch(
        setMessage(
          cvs === 0
            ? "Job created successfully!"
            : "Job created successfully and you will be notified over email once CV's parsing is completed!",
          "success"
        )
      );
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in creating job", error.response.data.error);
    dispatch(hideLoading());
    dispatch(setMessage(error.response.data.error, "error"));
  }
};

export const uploadCVs = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _uploadCVs(data, id);

    if (response.status === 200) {
      dispatch({
        type: UPLOAD_CVS_JOB_SUCCESS,
        payload: response.data,
      });
      // dispatch(getJobs());
      dispatch(
        setMessage(
          "Batch created successfully and you will be notified over email once CV's parsing is completed!",
          "success"
        )
      );
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in creating job", error.response.data.error);
    dispatch(hideLoading());
    dispatch(setMessage(error.response.data.error, "error"));
  }
};
export const deleteJob = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _deleteJob(id);

    if (response.status === 200) {
      dispatch({
        type: DELETE_JOB_SUCCESS,
        payload: response.data,
      });
      dispatch(setMessage("Job deleted successfully", "success"));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in deleting job", error);
    dispatch(hideLoading());
    dispatch(
      setMessage("There is an issue deleting job, please try again!", "error")
    );
  }
};

export const updateJob = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _updateJob(id, data);

    if (response.status === 200) {
      dispatch({
        type: UPDATE_JOB_SUCCESS,
        payload: response.data,
      });
      dispatch(getJob(id));
      dispatch(setMessage("Job updated successfully", "success"));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in updating job", error);
    dispatch(hideLoading());
    dispatch(
      setMessage("There is an issue updating job, please try again!", "error")
    );
  }
};

export const updateJobSkills = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _updateJobSkills(id, data);

    if (response.status === 200) {
      dispatch({
        type: UPDATE_JOB_SKILLS_SUCCESS,
      });
      dispatch(setMessage("Job skills updated successfully", "success"));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in updating job", error);
    dispatch(hideLoading());
    dispatch(
      setMessage("There is an issue updating job, please try again!", "error")
    );
  }
};

export const updateJobStatus = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _updateJobStatus(id, data);

    if (response.status === 200) {
      dispatch({
        type: UPDATE_JOB_STATUS_SUCCESS,
        payload: response.data,
      });
      dispatch(setMessage("Job status updated successfully", "success"));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in updating job status", error);
    dispatch(hideLoading());
    dispatch(
      setMessage(
        "There is an issue updating job status, please try again!",
        "error"
      )
    );
  }
};

export const clearAddJobData = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADD_JOB_DATA,
  });
};

export const getCourseList = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _getCourseList();

    if (response.status === 200) {
      dispatch({
        type: GET_ALL_LIST_COURSES,
        payload: response.data,
      });
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in getting list data", error);
    dispatch(hideLoading());
  }
};

export const createAudioVideo = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _createAudioVideo(id, data);
    if (response.status === 200) {
      dispatch({
        type: CREATE_AUDIO_VIDEO,
        payload: response.data,
      });
      dispatch(setMessage("Audio Video created successfully", "success"));
    }
    dispatch(hideLoading());
  } catch (error) {
    console.error("error in creating Audio Video", error.response.data.error);
    dispatch(hideLoading());
    dispatch(setMessage(error.response.data.error, "error"));
  }
};
