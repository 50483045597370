import axios from "axios";

export const setAuthToken = (token) => {
  let userToken = localStorage.getItem('token') || token
  if (userToken) {
    axios.defaults.headers.common["x-api-key"] = `${userToken}`;
  } else {
    delete axios.defaults.headers.common["x-api-key"];
  }
};
