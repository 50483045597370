import { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AIInterview from "./AIInterview";
import { getApplicant } from "../../redux/actions/applicant";
import { getInterviewStatus } from "../../redux/actions/candidate";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import useFullScreen from "../../hooks/fullScreenWindow";

const CandidateDashboard = ({
  getApplicant,
  applicant,
  skills,
  getInterviewStatus,
  auth: { user },
  interviewStatus,
}) => {
  const [mcqCompleted, setMcqCompleted] = useState(false);
  const [codingCompleted, setCodingCompleted] = useState(false);
  const { isFullScreen, enterFullScreen, exitFullScreen } = useFullScreen();

  const navigate = useNavigate(); // Initialize useNavigate
  useEffect(() => {
    if (user && user.u_apct_id_fk) {
      getInterviewStatus(user.u_apct_id_fk);
      getApplicant(user.u_apct_id_fk);
    }
  }, [user]);

  useEffect(() => {
    if (applicant) {
      console.log('Coding Conditions: ', !applicant.apct_coding && applicant.apct_coding_complete);
      setMcqCompleted(!applicant.apct_mcq && applicant.apct_mcq_complete);
      setCodingCompleted(!applicant.apct_coding && applicant.apct_coding_complete);
    }
  }, [applicant]);

  const handleStartMcqTest = () => {
    // Make Full Screen of Application
    enterFullScreen()
    navigate("/candidate/mcq-test"); // Navigate to MCQ Test
  };

  const handleStartCodingTest = () => {
    enterFullScreen()
    navigate("/candidate/coding-test"); // Navigate to Coding Test
  };

  return (
    <>
      {applicant?.apct_mcq || applicant?.apct_coding || interviewStatus ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {!mcqCompleted && applicant?.apct_mcq? (
            <Button variant="contained" color="primary" onClick={handleStartMcqTest}>
              Start MCQ Test
            </Button>
          ) : !codingCompleted && applicant?.apct_coding? (
            <Button variant="contained" color="primary" onClick={handleStartCodingTest}>
              Start Coding Test
            </Button>
          ) : (
            interviewStatus &&
            interviewStatus.apct_stage !== "completed" && (
              <AIInterview userId={user && user.u_id} userConsent={applicant?.apct_user_consent}/>
            )
          )}
        </Box>
      ) : (
        <Typography variant="subtitle2" textAlign="center" color="primary">
          No tests available.
        </Typography>
      )}

      {interviewStatus && interviewStatus.apct_stage === "completed" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="primary">
            Your AI interview is completed, HR will get back to you.
          </Typography>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  interviewStatus: state.candidate.interviewStatus,
  applicant: state.applicant?.applicant?.applicant,
  skills: state.applicant?.applicant?.skills,
});

const mapDispatchToProps = { getInterviewStatus, getApplicant };

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDashboard);
