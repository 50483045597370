import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { connect } from "react-redux";
import { signupUser } from "../../redux/actions/auth";
import Copyright from "../../components/Copyright";
import Logo from "../../components/Logo";
import {
  password_validate,
  email_validate,
  company_name_validate,
} from "../../utils";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

// TODO remove, this demo shouldn't need to reset the theme.

const SignUp = ({ signupUser, auth: { isLoggedIn } }) => {
  const [signupData, setSignupData] = useState({
    name: "",
    username: "",
    org: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(
    "minimum 8 character length"
  );
  const [emailError, setEmailError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [optSent, setOtpSent] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (localStorage.getItem("otp_sent")) {
      setOtpSent(true);
    }
  }, [localStorage.getItem("otp_sent")]);

  const handleSignupChange = (e) => {
    const { name, value } = e.target;
    if (name === "password" && value.length > 7) {
      setPasswordError("");
    }
    setSignupData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email_validate(signupData.email)) {
      setEmailError("invalid email");
      return;
    }
    setEmailError("");
    if (!company_name_validate(signupData.org)) {
      setCompanyError(
        "company name should contain only alphabets, spaces and numbers, and should not start with a number"
      );
      return;
    }
    setCompanyError("");

    if (!password_validate(signupData.password)) {
      setPasswordError(
        "password should contain atleast 1 capital letter, 1 digit and 1 special character"
      );
      return;
    }
    setPasswordError("");
    const captchaToken = await executeRecaptcha("sign_up");
    // setSignupData((prevData) => ({
    //   ...prevData,
    //   reCaptchaToken: captchaToken,
    // }));
    const dataWithCaptcha = {
      ...signupData,
      reCaptchaToken: captchaToken,
    };
    await signupUser(dataWithCaptcha);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(true);
  };

  return (
    <Grid container item xs={12} sm={12}>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // alignContent: "center",
          minHeight: "100vh",
          justifyContent: "center",
          background: "white",
        }}
      >
        <Logo />

        {optSent ? (
          <Typography variant="h6" sx={{ mt: 2 }}>
            OTP sent to your email, please verify!
          </Typography>
        ) : (
          <>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Sign Up
            </Typography>
            <Box sx={{ mt: 3, width: 350 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 2, width: 350, height: 50 }}
                    required
                  >
                    <InputLabel htmlFor="outlined-username">
                  Full Name
                    </InputLabel>
                    <OutlinedInput
                      autoComplete="given-name"
                      name="name"
                      id="name"
                      label="Name"
                      autoFocus
                      focused
                      value={signupData.name}
                      onChange={handleSignupChange}
                    />
                  </FormControl>
                </Grid>
                

                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 2, width: 350, height: 50 }}
                    required
                  >
                    <InputLabel htmlFor="outlined-username">
                      User Name
                    </InputLabel>
                    <OutlinedInput
                      autoComplete="given-name"
                      name="username"
                      fullWidth
                      id="username"
                      label="User Name"
                      autoFocus
                      focused
                      value={signupData.username}
                      onChange={handleSignupChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 2, width: 350, height: 50 }}
                    required
                  >
                    <InputLabel htmlFor="outlined-email">Email</InputLabel>
                    <OutlinedInput
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      focused
                      value={signupData.email}
                      onChange={handleSignupChange}
                    />
                    <Typography variant="caption" color="error">
                      {emailError}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 2, width: 350, height: 50 }}
                    required
                  >
                    <InputLabel htmlFor="outlined-text">Company</InputLabel>
                    <OutlinedInput
                      required
                      fullWidth
                      id="text"
                      label="Company"
                      name="org"
                      autoComplete="org"
                      focused
                      value={signupData.org}
                      onChange={handleSignupChange}
                    />
                    <Typography variant="caption" color="error">
                      {companyError}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    sx={{ mt: 2, width: 350, height: 50 }}
                    required
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      name="password"
                      label="Password"
                      autoComplete="current-password"
                      value={signupData.password}
                      onChange={handleSignupChange}
                      focused
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography variant="caption" color="error">
                      {passwordError}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                disabled={
                  !signupData.email ||
                  !signupData.password ||
                  !signupData.username ||
                  !signupData.org ||
                  signupData.password.length < 8
                }
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/signin" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        {optSent ? "" : <Copyright sx={{ mt: 5 }} />}
      </Grid>
      <Grid item xs={6} sm={6}>
        <div className="container-fluid homepage-bgimage"></div>
      </Grid>
    </Grid>
  );
};

const SignUpWithReCaptcha = ({ signupUser, auth: { isLoggedIn } }) => {
  const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_API_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <SignUp signupUser={signupUser} auth={{ isLoggedIn }} />
    </GoogleReCaptchaProvider>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { signupUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpWithReCaptcha);
