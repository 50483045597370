import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { roles } from "../data/menuData";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const SideBar = ({
  open,
  handleDrawerClose,
  setMainLayout,
  mainLayout,
  role,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [menu, setMenu] = useState([]);
  const [selectedMenuId, setSelectedMenuId] = useState("");

  useEffect(() => {
    let r = roles.find((r) => r.value === role);
    if (r) {
      //console.log("R=>Manu=>", r.menus);
      setMenu(r.menus);
      setSelectedMenuId(r.menus[0].id);
    }
  }, [role]);

  const onClickButton = (e, value, isSubMenu) => {
    // console.log(e, value, isSubMenu);
    e.preventDefault();
    if (isSubMenu) {
      setSelectedMenuId(value.id);
    }
    setMainLayout(value);
    navigate(value.path);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Logo />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />

      <List component="nav">
        {menu.map((m, i) => {
          return (
            <Fragment key={i}>
              <ListItemButton
                key={i}
                selected={mainLayout.path === m.path}
                onClick={(e) =>
                  onClickButton(
                    e,
                    m,
                    m.submenu && m.submenu.length > 0 ? true : false
                  )
                }
              >
                <ListItemIcon>{m.icon}</ListItemIcon>
                <ListItemText primary={m.label} />
                {m.submenu && m.submenu.length > 0 ? (
                  <>
                    {selectedMenuId === m.id ? <ExpandLess /> : <ExpandMore />}
                  </>
                ) : null}
              </ListItemButton>
              {m.submenu &&
                m.submenu.length > 0 &&
                m.submenu.map((sm, i) => (
                  <Collapse
                    key={i}
                    in={selectedMenuId === m.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={mainLayout.path === sm.path}
                        onClick={(e) => onClickButton(e, sm, false)}
                      >
                        <ListItemIcon>{sm.icon}</ListItemIcon>
                        <ListItemText primary={sm.label} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
            </Fragment>
          );
        })}
      </List>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
