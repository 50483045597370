import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";
import {
  Stack,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import WebcamVideo from "../../components/Recorder/VideoRecorder";
import {
  startInterview,
  concludeInt,
  submitAIanswer,
  giveConsent,
} from "../../redux/actions/candidate";
import { getApplicant } from "../../redux/actions/applicant";
import AlertDialog from "../../components/Dialog";
import { useNavigate } from "react-router-dom";
import { Userprofile } from "../../redux/actions/auth";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import useExitPrompt from "../../hooks/exitPropmts";
import { toast } from "react-toastify";
import { hideSidebar, showSidebar } from "../../redux/actions/sidebar";
import useFullscreen from "../../hooks/fullScreenWindow";

export const InterviewPage = ({
  submitAIanswer,
  concludeInt,
  Userprofile,
  currentUserDetails,
  interviewData,
  submitAnsRes,
  startInterview,
  interviewEnded,
  giveConsent,
  userConsent,
  getApplicant,
  hideSidebar,
  showSidebar,
}) => {
  const navigate = useNavigate();
  const { speak } = useSpeechSynthesis();
  const [chatThread, setChatThread] = useState([]);
  const [response, setResponse] = useState({});
  const [threadId, setThreadId] = useState(null);
  const [intwId, setIntwId] = useState(null);
  const [assistId, setAssistId] = useState(null);
  const [vectId, setVectId] = useState(null);
  const [qaId, setQaId] = useState(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [userId, setUserId] = useState("");
  const [startInt, setStartInt] = useState(false);
  const [audio, setAudio] = useState(null);
  const [mute, setMute] = useState(true);
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [mediaStream, setMediaStream] = useState(null); // State to hold media stream

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const { isFullScreen, enterFullScreen } = useFullscreen(); // Use the custom hook

  useEffect(() => {
    if (interviewEnded) {
      setOpen(false);
      setConfirm(false);
      navigate("/dashboard");
      cleanupResources(); // Clean up resources when the interview ends
    }
  }, [interviewEnded]);

  useEffect(() => {
    if (response && response.question && !mute) {
      speak({ text: response.question });
    }
  }, [response, mute]);

  // Handle the starting of the interview
  const initiateInterview = async () => {
    try {
      await startInterview(currentUserDetails.u_apct_id_fk);
    } catch (error) {
      console.error("Failed to start the interview", error);
    }
  };

  const handleStartButtonClick = async () => {
    if (!isFullScreen) {
      enterFullScreen(); // Enter fullscreen mode on user click
    }
    setStartInt(true);
  };

  useEffect(() => {
    if (startInt) {
      if (!userConsent) {
        setShowConsentPopup(true); // Show consent popup if consent is not given
      } else {
        initiateInterview(); // Start the interview directly if consent is already given
      }
    }
  }, [startInt, userConsent]);

  useEffect(() => {
    if (userConsent && startInt) {
      initiateInterview(); // Ensure interview starts if consent is given
    }
  }, [userConsent]);

  useEffect(() => {
    let timer = setTimeout(() => Userprofile(), 1000); // Adjust timeout duration as needed
    return () => {
      clearTimeout(timer);
    };
  }, [Userprofile]);

  useEffect(() => {
    hideSidebar();
  }, [hideSidebar]);

  useEffect(() => {
    if (currentUserDetails) {
      setUserId(currentUserDetails?.u_id);
    }
  }, [currentUserDetails]);

  useEffect(() => {
    if (interviewData) {
      setThreadId(interviewData.thread_id);
      setIntwId(interviewData.intw_id);
      setAssistId(interviewData.assistant_id);
      setVectId(interviewData.vector_store_id);
      setQaId(interviewData.intqa_id);
      setQuestionCount(1);
      setResponse({
        id: uuidv4(),
        question: interviewData.question,
        answer: "",
      });
    }
  }, [interviewData]);

  useEffect(() => {
    if (submitAnsRes) {
      setThreadId(submitAnsRes.thread_id);
      setIntwId(submitAnsRes.intw_id);
      setAssistId(submitAnsRes.assistant_id);
      setVectId(submitAnsRes.vector_store_id);
      setQaId(submitAnsRes.intqa_id);
      setQuestionCount(submitAnsRes.questionsCount);
      setResponse({
        id: uuidv4(),
        question: submitAnsRes.response,
        answer: submitAnsRes.transcription,
      });
      setChatThread(submitAnsRes.QAs);
    }
  }, [submitAnsRes]);

  const submitData = () => {
    setTitle("Conclude Interview");
    setContent("Are you sure you want to conclude this interview?");
    setOpen(true);
  };

  useEffect(() => {
    if (confirm) {
      concludeInt({
        intwId,
        threadId,
        apct_id: currentUserDetails.u_apct_id_fk,
      });
      cleanupResources(); // Clean up resources when concluding the interview
      showSidebar();
    }
  }, [confirm, concludeInt, intwId, threadId, currentUserDetails, showSidebar]);

  useEffect(() => {
    if (audio) {
      const data = {
        audio_id: audio,
        thread_id: threadId,
        intw_id: intwId,
        assistant_id: assistId,
        vector_store_id: vectId,
        intqa_id: qaId,
      };

      submitAIanswer(data);
      setAudio(null);
    }
  }, [audio, submitAIanswer, threadId, intwId, assistId, vectId, qaId]);

  const handleConsentChange = (event) => {
    setConsentGiven(event.target.checked);
  };

  const handleConsentApproval = async () => {
    if (consentGiven) {
      await requestMediaPermissions();
      setShowConsentPopup(false);
    }
  };

  const requestMediaPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setMediaStream(stream); // Store the media stream in state
      await giveConsent(currentUserDetails.u_apct_id_fk);
      await getApplicant(currentUserDetails.u_apct_id_fk);
      initiateInterview(); // Start the interview after giving consent
    } catch (error) {
      console.error("Media permissions denied", error);
      toast.error("Media permissions are required to proceed with the interview.");
    }
  };

  // Function to stop all media tracks (camera and microphone) and exit fullscreen
  const cleanupResources = () => {
    stopMediaTracks();
    exitFullscreen();
  };

  // Function to stop all media tracks (camera and microphone)
  const stopMediaTracks = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null); // Clear the media stream from state
    }
  };

  // Function to exit fullscreen mode
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error("Error exiting fullscreen:", err);
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === 'F12' ||
        (event.altKey && event.key === 'F4') ||
        event.key === 'Escape'
      ) {
        event.preventDefault();
        toast.error("Invalid Action");
      }
  
      if (event.ctrlKey && (event.key === 'c' || event.key === 'x' || event.key === 'v')) {
        event.preventDefault();
        const actions = { c: 'Copying', x: 'Cutting', v: 'Pasting' };
        toast.error(`${actions[event.key]} is not allowed`);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Stack sx={{ display: "flex", flexDirection: "column" }}>
      <Dialog open={showConsentPopup} onClose={() => setShowConsentPopup(false)}>
        <DialogTitle>Consent Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To proceed, we need your consent to capture audio and video for the interview process.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={consentGiven}
                onChange={handleConsentChange}
                name="consent"
                color="primary"
              />
            }
            label="I give my consent to capture audio and video."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConsentApproval} disabled={!consentGiven} color="primary">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog open={open} setOpen={setOpen} title={title} setConfirm={setConfirm} content={content} />

      <Stack sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="subtitle2" textAlign="center" color="primary">Welcome to</Typography>
        <Typography variant="h4" textAlign="center" color="primary">AI Interview Assistant</Typography>
        {threadId && (
          <>
            <Button style={{ marginTop: "10px", width: "10vw", textAlign: "center" }} variant="contained" onClick={submitData} disabled={!threadId}>
              Conclude
            </Button>
            <Typography variant="caption" color="green" sx={{ mt: 1 }}>
              * Please conclude interview before leaving the page
            </Typography>
          </>
        )}
      </Stack>

      {threadId ? (
        <Stack sx={{ mt: 2, display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 3 }}>
          <Card sx={{ width: "50%" }}>
            <Typography textAlign="center"></Typography>
            <WebcamVideo threadId={threadId} userId={userId} setAudio={setAudio} qaId={qaId} intwId={intwId} />
          </Card>
          <Card sx={{ width: "50%" }}>
            <CardHeader />
            <CardContent>
              {response.question && (
                <>
                  <Typography>
                    <IconButton onClick={() => setMute(!mute)}>
                      {mute ? <VolumeOffIcon /> : <VolumeUpIcon />} :
                    </IconButton>
                    {response.question}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <Stack sx={{ mt: 3, display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Button variant="contained" onClick={handleStartButtonClick}>Start</Button>
        </Stack>
      )}

      {chatThread.length > 0 && (
        <Stack sx={{ mt: 5, ml: 3, display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">AI Post Interview Analysis</Typography>
          {chatThread.map((res) => (
            res.intqa_answer && (
              <Card key={res.intqa_id} sx={{ minWidth: "30vw" }}>
                <CardContent>
                  <Typography variant="h6">🤖 : {res.intqa_question}</Typography>
                  <Typography>🧑 : {res.intqa_answer}</Typography>
                </CardContent>
              </Card>
            )
          ))}
        </Stack>
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  currentUserDetails: state.auth.user,
  interviewData: state.candidate.interviewData,
  submitAnsRes: state.candidate.submitAnsRes,
  interviewEnded: state.candidate.interviewEnded,
  userConsent: state.applicant?.applicant?.applicant?.apct_user_consent,
});

const mapDispatchToProps = {
  startInterview,
  submitAIanswer,
  concludeInt,
  Userprofile,
  giveConsent,
  getApplicant,
  hideSidebar,
  showSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewPage);
