import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { getAIInterviewScore } from '../../services/dashboard';
import { useParams, Link } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import html2pdf from 'html2pdf.js';

// Function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const AiScoreReport = () => {
  const { apct_id } = useParams();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchAIScoreData = async () => {
    for (let i = 0; i < 10; i++) {
      let interviewData = await getAIInterviewScore(apct_id);
      if (interviewData.status === 200) {
        setResponse(interviewData.data);
        setLoading(false);
        break;
      }
    }
  };

  const handleDownload = () => {
    const element = document.getElementById('report');
    const options = {
      filename: `ai-score.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'in', format: 'A3', orientation: 'portrait' },
    };

    html2pdf().from(element).set(options).save();
  };

  useEffect(() => {
    fetchAIScoreData();
  }, []);

  const { interview_report, mcq_report = [], coding_report = [] } = response;
  const { interviewerName, positionApplied, jobCode, feedback, questionList, answerList } = interview_report?.response || {};
  const config = {
    displayModeBar: 'hover',
    displaylogo: false,
    responsive: true,
  };

  // Function to calculate MCQ report summary
  const calculateMCQSummary = () => {
    const totalQuestions = mcq_report.length;
    const totalCorrect = mcq_report.filter(mcq => mcq.as_is_correct).length;
    const totalWrong = totalQuestions - totalCorrect;
    const correctPercentage = ((totalCorrect / totalQuestions) * 100).toFixed(2);

    return { totalQuestions, totalCorrect, totalWrong, correctPercentage };
  };

  const mcqSummary = calculateMCQSummary();

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button onClick={handleDownload}>Download as PDF</Button>
        <h1 style={{ textAlign: 'center', flex: 1 }}>Score Report</h1>
        <Link to="/hr/candidates" style={{ marginRight: '20px' }}>Back</Link>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div style={{ textAlign: 'center' }} id="report">
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <p><strong>Interviewee:</strong> {interviewerName}</p>
            <p><strong>Position Applied:</strong> {positionApplied}</p>
            <p><strong>Job Code:</strong> {jobCode}</p>
          </div>

          {/* MCQ Report Section */}
          {mcq_report.length > 0 && (
            <>
              <h2>MCQ Report</h2>
              <Typography variant="body1">
                <strong>Total Questions:</strong> {mcqSummary.totalQuestions} | 
                <strong> Correct:</strong> {mcqSummary.totalCorrect} | 
                <strong> Wrong:</strong> {mcqSummary.totalWrong} | 
                <strong> Percentage Correct:</strong> {mcqSummary.correctPercentage}%
              </Typography>
              <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Question No.</strong></TableCell>
                      <TableCell><strong>Answer Given</strong></TableCell>
                      <TableCell><strong>Correct</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mcq_report.map((mcq, index) => (
                      <TableRow key={mcq.as_id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{mcq.as_answer_given}</TableCell>
                        <TableCell>{mcq.as_is_correct ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {/* Coding Report Section */}
          {coding_report.length > 0 && (
            <>
              <h2>Coding Report</h2>
              <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Question No.</strong></TableCell>
                      <TableCell><strong>Score</strong></TableCell>
                      <TableCell><strong>Report</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {coding_report.map((coding, index) => (
                      <TableRow key={coding.as_id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{coding.as_coding_score}</TableCell>
                        <TableCell>{JSON.parse(coding.as_coding_report)?.Evaluation?.Overall_Assessment || 'N/A'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {/* AI Interview Feedback Section */}
          <h2>AI Interview Report</h2>
          {feedback?.map((item, index) => {
            const { question_id, score, emotion_score, brief_explanation } = item;
            const scores = Object.values(score);
            const labels = Object.keys(score).map(capitalizeFirstLetter);
            const emotionsScores = emotion_score ? Object.values(emotion_score) : [];
            const emotionsLabels = emotion_score ? Object.keys(emotion_score).map(capitalizeFirstLetter) : [];

            return (
              <Card key={question_id} style={{ marginBottom: '40px' }}>
                <CardContent>
                  <Typography variant="h5" component="h2">Question {index + 1}</Typography>
                  <Typography variant="body1">{questionList[index]}</Typography>
                  <Typography variant="body2"><strong>Answer:</strong> {answerList[index]}</Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Plot
                      data={[{ type: 'pie', values: scores, labels, textinfo: 'label+percent', insidetextorientation: 'radial' }]}
                      layout={{ title: `Score Breakdown for Question ${+question_id + 1}` }}
                      config={config}
                      style={{ flex: 1 }}
                    />
                    <table style={{ flex: 1, marginLeft: '20px', borderCollapse: 'collapse', width: '50%' }}>
                      <thead>
                        <tr>
                          <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
                          <th style={{ border: '1px solid black', padding: '8px' }}>Score (out of 10)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {labels.map((label, i) => (
                          <tr key={i}>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{label}</td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>{scores[i]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Emotion Analysis Table */}
                  {emotionsScores && emotionsScores.length ? (
                                        <>
                                            <Typography variant="h6" component="h3">Emotions Analysis</Typography>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Plot
                                                    data={[
                                                        {
                                                            type: 'bar',
                                                            x: emotionsLabels, // Labels go on the x-axis
                                                            y: emotionsScores, // Scores go on the y-axis
                                                            text: emotionsScores.map(String), // Add text labels for each bar
                                                            textposition: 'auto',
                                                            hoverinfo: 'label+percent',
                                                            marker: {
                                                                color: 'rgba(55,128,191,0.6)',
                                                                line: {
                                                                    color: 'rgba(55,128,191,1.0)',
                                                                    width: 2
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    layout={{
                                                        title: `Emotion Breakdown for Question ${feedback[0]?.question_id ===0?+question_id+1:question_id}`,
                                                        xaxis: { title: 'Emotions' },
                                                        yaxis: { title: 'Scores (out of 10)' }
                                                    }}
                                                    config={config}
                                                    style={{ flex: 1 }}
                                                />
                                                <table style={{ flex: 1, marginLeft: '20px', borderCollapse: 'collapse', width: '50%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Criteria</th>
                                                            <th style={{ border: '1px solid black', padding: '8px' }}>Score (out of 10)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {emotionsLabels.map((label, i) => (
                                                            <tr key={i}>
                                                                <td style={{ border: '1px solid black', padding: '8px' }}>{label}</td>
                                                                <td style={{ border: '1px solid black', padding: '8px' }}>{emotionsScores[i]}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) : null}
                  <Typography variant="body2"><strong>Explanation:</strong> {brief_explanation}</Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AiScoreReport;
