import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Divider,
  Box,
  Grid,
  ListItem,
  List,
} from "@mui/material";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getApplicantVideo,
  playApplicantVideo,
} from "../../redux/actions/applicant";

const CandidateDetails = ({ applicant_video, getApplicantVideo }) => {
  const { candidateId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const apVideos = applicant_video.rows;

  const [videoSrc, setVideoSrc] = useState("");
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    getApplicantVideo(candidateId);
  }, [candidateId, getApplicantVideo]);

  const handleVideoPlay = (id) => {
    const url = `${API_URL}/get_recording/${id}/video`;
    playApplicantVideo(id, "video");
    setVideoSrc(url);
    setActiveId(id);
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Card xs={12} sx={{ mt: 1, p: 4, height: "calc(100vh - 120px)" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
              Candidate/Employee Audio Video Details
            </Typography>
          </Box>

          <Divider />

          <Box sx={{ width: "100%", height: "100%", overflowY: "auto", mt: 4 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6} sx={{ height: "420px" }}>
                <video
                  width="100%"
                  controls
                  src={
                    videoSrc ||
                    (apVideos &&
                      `${API_URL}/get_recording/${apVideos[0].r_id}/video`)
                  }
                >
                  Your browser does not support the video tag.
                </video>
              </Grid>
              <Grid item xs={6}>
                <List sx={{ m: 0, p: 0 }}>
                  {apVideos &&
                    apVideos.length > 0 &&
                    apVideos.map((video) => (
                      <ListItem
                        key={video.r_id}
                        sx={{
                          background:
                            activeId === video.r_id ? "#137815" : "#eeeeee",
                          color: activeId === video.r_id && "#ffffff",
                          marginBottom: 2,
                          cursor: "pointer",
                        }}
                        onClick={() => handleVideoPlay(video.r_id)}
                      >
                        {video.r_created_at} -- {video.r_id}
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  applicant_video: state.applicant.applicant_video,
  play_applicant_video: state.applicant.play_applicant_video,
});

const mapDispatchToProps = {
  getApplicantVideo,
  playApplicantVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetails);
