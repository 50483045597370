import { Route, Routes, Navigate } from "react-router-dom";
import AIInterview from "../pages/candidate/AIInterview";
import CandidateDashboard from "../pages/candidate/Dashboard";
import UsersProfile from "../pages/common/UsersProfile";
import Setting from "../pages/common/Setting";
import TestMCQ from "../pages/candidate/TestMCQ";
import TestCoding from "../pages/candidate/TestCoding";
const CandidateRoutes = () => {
  return (
    <Routes>
      <Route path="/candidate/dashboard" element={<CandidateDashboard />} />
      <Route path="/candidate/ai-interview" element={<AIInterview />} />
      <Route path="/candidate/mcq-test" element={<TestMCQ />} />
      <Route path="/candidate/coding-test" element={<TestCoding />} />
      <Route path="/candidate/ai" element={<AIInterview />} />
      <Route path="/candidate/profile" element={<UsersProfile />} />
      <Route path="/candidate/setting" element={<Setting />} />
      <Route
        path="*"
        element={<Navigate to="/candidate/dashboard" replace />}
      />
    </Routes>
  );
};

export default CandidateRoutes;
