import { useState, useEffect } from "react";
import { Typography, Card, CardContent, Divider, Tabs, Tab } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { getAllSkills } from "../../redux/actions/skills";
import { formatDate } from "../../utils/dateUtils";

export const Skills = ({ getAllSkills, skills }) => {

  const [currentStatus, setCurrentStatus] = useState('active');
  const [rows, setRows] = useState([]);
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  useEffect(() => {
    getAllSkills(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals, getAllSkills]);
  useEffect(() => {
    updateTableHeight(skills?.length);
  }, [paginationModals.pageSize, skills]);
  useEffect(() => {
    setRows(skills);
  }, [skills]);

  const handleProcessRowUpdate = (newRow) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  const columns = [
    // {
    //   field: "sl_id",
    //   headerName: "sl_id",
    //   flex: 1,
    //   minWidth: 200,
    // },
    {
      field: "sl_name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "sl_status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "sl_internal_name",
      headerName: "Internal Name",
      flex: 1,
      editable: true,
    },
    {
      field: "sl_created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "sl_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.sl_created_at
            ? formatDate(JSON.parse(localStorage.getItem("user")), params.row.sl_created_at)
            : ""}
        </>
      ),
    },
  ];
  useEffect(() => {
    getAllSkills(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals, getAllSkills]);

  const filteredSkills = skills?.filter(skill => skill.sl_status === currentStatus);
  const rowsWithId = filteredSkills ? filteredSkills.map((item, index) => ({ ...item, id: item.sl_id || index })) : [];

  return (
    <>
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Typography variant="h5">Skills</Typography>
          <Divider />
          <Tabs 
            value={currentStatus}
            onChange={(event, newValue) => setCurrentStatus(newValue)}
            variant="standard"
            aria-label="Status tabs"
            >
              <Tab label="Active" value="active" />
              <Tab label="Inactive" value="inactive" />
              <Tab label="Draft" value="draft" />
            </Tabs>
          <div style={{ height: tableHeight, width: '100%' }}>
          <ServerPaginationGridNoRowCount
            rows={rows}
            setPaginationModals={setPaginationModals}
            paginationModals={paginationModals}
            rowsData={rowsWithId}
            total={skills ? skills.length : 0}
            columns={columns}
            processRowUpdate={handleProcessRowUpdate}
          />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  skills: state.skills.allSkills,
});

const mapDispatchToProps = { getAllSkills };

export default connect(mapStateToProps, mapDispatchToProps)(Skills);

