import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const _getBatch = async (id) => {
    try {
        const url = `${API_URL}/batch/${id}`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        throw error;
    }
};

export const _getBatchProgress = async (id) => {
  try {
    const url = `${API_URL}/batch/progress/${id}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

//_getBatchProgress

export const _updateBatchTitle = async (id, data) => {
    try {
        const url = `${API_URL}/batch/update_title/${id}`;
        const response = await axios.put(url, data);
        return response;
    } catch (error) {
        throw error;
    }
}

export const _getAllBatches = async (id, page, size, filters, search) => {
  try {
    let url = `${API_URL}/jobs/batches/${id}?page=${page}&pageSize=${size}`;
      if (filters) {
        url = url + filters;
      }
      if (search) {
        url = url + search;
      }
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const _deleteBatch = async (id) => {
    try {
        const url = `${API_URL}/batch/${id}`;
        const response = await axios.delete(url);
        return response;
    } catch (error) {
        throw error;
    }
}

export const _getBatchNameByJobId = async (id) => {
    try {
      const url = `${API_URL}/batch/get_model_with_jobid/${id}`;
        const response = await axios.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}