import React, { useEffect, useRef, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { io } from "socket.io-client";
import { createAudioVideo } from "../../redux/actions/jobs";
import { connect } from "react-redux";
import * as faceapi from "face-api.js";
import { v4 as uuidv4 } from "uuid";

const API_URL = process.env.REACT_APP_WS_URL;
const socket = io(API_URL, {});

const constraints = {
  audio: {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
  },
  video: {
    // width: { min: 1024, ideal: 1280, max: 1920 },
    // height: { min: 576, ideal: 720, max: 1080 },
    width: 640,
    height: 480,
    aspectRatio: 1.7777777778,
    frameRate: { ideal: 10, max: 20 },
    facingMode: "user",
  },
};

export const WebcamVideo = ({ threadId, userId, setAudio, qaId, intwId }) => {
  const [permission, setPermission] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const mediaRecorder = useRef(null);
  const videoRef = useRef(null);
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const audioRecorder = useRef(null);
  const videoRecorder = useRef(null);
  

   const canvasRef = useRef();

   useEffect(() => {
     const loadModels = async () => {
       const MODEL_URL = process.env.PUBLIC_URL + "/models";

       Promise.all([
         faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
         faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        //  faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
         faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        //  faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL),
       ]).then(() => {
         console.log("Models Loaded");
         
       });
     };
     loadModels();
   }, []);
  
   const handleVideoOnPlay = () => {
     setInterval(async () => {
       if (canvasRef && canvasRef.current) {
         canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
           videoRef.current
         );
         const displaySize = {
           width: 640,
           height: 480,
         };

         faceapi.matchDimensions(canvasRef.current, displaySize);

         const detections = await faceapi
           .detectAllFaces(
             videoRef.current,
             new faceapi.TinyFaceDetectorOptions()
           )
           .withFaceLandmarks()
           .withFaceExpressions();

         const resizedDetections = faceapi.resizeResults(
           detections,
           displaySize
         );

         canvasRef &&
           canvasRef.current &&
           canvasRef.current
             .getContext("2d")
             .clearRect(0, 0, 640, 480);
         canvasRef &&
           canvasRef.current &&
           faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        //  canvasRef &&
        //    canvasRef.current &&
        //    faceapi.draw.DrawTextField(canvasRef.current, resizedDetections);
         canvasRef &&
           canvasRef.current &&
           faceapi.draw.drawFaceExpressions(
             canvasRef.current,
             resizedDetections
           );
        // resizedDetections.forEach((detection) => {
        //   const box = detection.detection.box;
        //   const drawBox = new faceapi.draw.DrawBox(box, {
        //     label: Math.round(detection.age) + " | " + detection.gender,
        //   });
        //   drawBox.draw(canvasRef.current);
        // });
         
       }
     }, 3000);
  };
  


  const startVideoStream = async () => {
    try {

      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      videoRef.current.srcObject = videoStream;
      setVideoStream(videoStream);

      const videoMediaRecorder = new MediaRecorder(videoStream);
      videoMediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          // console.log('video-stream', event.data.size)
          let vdUserId = userId;
          // let streamId = videoStream.id;

          
          socket.emit("video-stream", {
            data: event.data,
            vdUserId,
           streamId : qaId,
          });
        }
      };
      videoMediaRecorder.start(100);
      videoRecorder.current = videoMediaRecorder;

      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setAudioStream(audioStream);

      const audioMediaRecorder = new MediaRecorder(audioStream);
      audioMediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          let vdUserId = userId;
          
          socket.emit("audio-stream", {
            data: event.data,
            vdUserId,
            streamId : qaId,
          });
        }
      };
      audioMediaRecorder.start(100);
      audioRecorder.current = audioMediaRecorder;

      setCapturing(true);
      socket.emit(
        "save-audio-video-stream",
        {
          userId: userId,
          aid: qaId,
          vid: qaId,
          qaId: qaId,
          intwId: intwId,
        },
        (data) => {
          console.log(data);
        }
      );
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };

  const stopVideoStream = () => {
    if (videoRecorder.current && videoRecorder.current.state === "recording") {
      videoRecorder.current.stop();
    }
    if (audioRecorder.current && audioRecorder.current.state === "recording") {
      audioRecorder.current.stop();
    }

    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
      setVideoStream(null);
    }
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }

    setCapturing(false);
  };

  useEffect(() => {
    return () => {
      if (videoRecorder.current) {
        videoRecorder.current.stop();
      }
      if (audioRecorder.current) {
        audioRecorder.current.stop();
      }
    };
  }, []);

  const getCameraPermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        // const streamData = await navigator.mediaDevices.getUserMedia(
        //   constraints
        // );
        setPermission(true);
      } catch (err) {
        alert(err.message);
        console.log("error in camera permission", err);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
      console.log("error in camera permission : 152");
    }
  };

  useEffect(() => {
    if (!permission) {
      getCameraPermission();
    }
  }, [permission]);

  const startRecording = async () => {
    startVideoStream();
    setCapturing(true);
    const streamData = await navigator.mediaDevices.getUserMedia(constraints);
    let videoTracks = streamData.getVideoTracks()[0];
    const video = document.getElementById("video");
    video.autoplay = true;
    video.srcObject = new MediaStream([videoTracks]);
    video.muted = true;
    video.play();

    let audioTracks = streamData.getAudioTracks()[0];
    const audioOutputStream = new MediaStream();
    audioOutputStream.addTrack(audioTracks);
    const media = new MediaRecorder(audioOutputStream, {
      mimeType: "audio/webm",
      audioBitsPerSecond: 128000,
      bitsPerSecond: 128000,
    });

    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    // setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    stopVideoStream();
    setCapturing(false);
    mediaRecorder.current.stop();
    setAudio(qaId);
    // mediaRecorder.current.onstop = () => {
    //   const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
    //   setAudioUrl(audioBlob);
    //   setAudioChunks([]);
    // };
    // (async function() {
    //   const streamData = await navigator.mediaDevices.getUserMedia(constraints);
    //   let obj = {
    //     userId,
    //     videoStream,
    //     capturing,
    //   };
    //   await createAudioVideo(obj.userId,);
    //   console.log('obj', obj)
    // })();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "100%",
      }}
    >
      <div
        id="videoContainer"
        className="videoContainer"
        style={{
          minWidth: "100%",
          margin: 0,
          padding: "0px 0px 20px",
          position: "relative",
        }}
      >
        <video
          id="video"
          ref={videoRef}
          className="video mirror"
          style={{ width: "100%" }}
          onPlay={handleVideoOnPlay}
        />
        <canvas id="canfaceapi" ref={canvasRef} className="face-canvas" />

        {!permission ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <IconButton onClick={() => getCameraPermission()}>
              <VideocamOffIcon />
            </IconButton>
            <div>
              <span
                style={{ fontSize: "12px", color: "red", textAlign: "center" }}
              >
                Please allow permission for camera and mic
              </span>
            </div>
          </div>
        ) : (
          <>
            <IconButton
              style={{
                marginTop: "10px",
                marginLeft: "42%",
                height: "60px",
                width: "60px",
              }}
              disabled={!threadId}
              // disabled={!modelsLoaded}
              onClick={capturing ? stopRecording : startRecording}
            >
              {capturing ? (
                <StopCircleIcon
                  style={{
                    height: "60px",
                    width: "60px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    zIndex: 99999999
                  }}
                />
              ) : (
                <>
                  <PlayCircleIcon
                    style={{
                      height: "60px",
                      width: "60px",
                      backgroundColor: "green",
                      color: "white",
                      borderRadius: "50%",
                      zIndex: 99999999
                    }}
                  />
                </>
              )}
            </IconButton>
              <Typography variant="caption" color="green" sx={{ml:1}}>Click to {capturing ? "stop" : "start"} recording</Typography>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  audioVideoList: state.jobs.audioVideoList,
});

const mapDispatchToProps = {
  createAudioVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebcamVideo);
