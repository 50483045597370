import { useState, useEffect } from "react";
import { Typography, Card, CardContent, Divider, IconButton } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { getEmployees } from "../../redux/actions/jobs";
import { Link } from "react-router-dom";
import SmartDisplayTwoToneIcon from "@mui/icons-material/SmartDisplayTwoTone";
import { formatDate } from "../../utils/dateUtils";

export const Employees = ({ getEmployees, employeesList  }) => {

  const columns = [
    {
      field: "u_name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Link to={`/hr/employees/employees-profile/${params.row.u_id}`}>
          {params.row.u_name}
        </Link>
      ),
    },
    {
      field: "se_technology",
      headerName: "Techonlogy",
      flex: 1,
    },
    {
      field: "se_department",
      headerName: "Department",
      flex: 1,
    },
    {
      field: "se_overall_experience",
      headerName: "Total Experience",
      flex: 1,
    },
    {
      field: "se_relevant_experience",
      headerName: " Relevant Experience",
      flex: 1,
    },
    {
      field: "se_total_skills",
      headerName: "Skills",
      flex: 1,
    },
    {
      field: "se_total_skill_gaps",
      headerName: "Skill Gaps",
      flex: 1,
    },
    {
      field: "se_skills_strength",
      headerName: "Skills Strength",
      flex: 1,
    },
    {
      field: "se_skill_gaps_score",
      headerName: "Skill Gaps Score",
      flex: 1,
    },
    {
      field: "u_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.u_created_at
            ? formatDate(JSON.parse(localStorage.getItem("user")), params.row.u_created_at)
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.se_current_step === 4 ? (
            <Link to={`/hr/employees/candidate-details/${params.row.id}`}>
              <IconButton sx={{ color: "#f70000" }}>
                <SmartDisplayTwoToneIcon />
              </IconButton>
            </Link>
          ) : (
            <IconButton disabled>
              <SmartDisplayTwoToneIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };
  useEffect(() => {
    getEmployees(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals]);
  
  useEffect(() => {
    updateTableHeight(employeesList?.rows?.length);
  }, [paginationModals.pageSize, employeesList]);
  return (
    <>
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Typography variant="h5">Employees</Typography>
          <Divider />
          <div style={{ height: tableHeight, width: '100%' }}>
          <ServerPaginationGridNoRowCount
            setPaginationModals={setPaginationModals}
            paginationModals={paginationModals}
            rowsData={
              employeesList && employeesList.count > 0 ? employeesList.rows : []
            }
            total={
              employeesList && employeesList.count > 0 ? employeesList.count : 0
            }
            columns={columns}
          />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  employeesList: state.jobs.employeesList,
});

const mapDispatchToProps = {
  getEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
