import {
  SET_MESSAGE,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  AUTH_USER,
  VERIFY_OTP_SUCCESS,
  USER_PROFILE,
  UPLOAD_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  VERIFY_RESET_TOKEN_SUCCESS,
  VERIFY_RESET_TOKEN_FAIL,
  UPDATE_USER_DETAILS,
} from "./types";
import {
  login,
  signup,
  logout,
  forgotPassword,
  auth,
  _verifyOtp,
  getuserprofile,
  changeuserPassword,
  uploadProfilePicture,
  resetPassword,
  verifyResetToken,
  updateUserDetails,
} from "../../services/auth.service";
import { showLoading, hideLoading } from "./loader";
import { setMessage } from "./message";

export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await login(data);

    if (response.status === 200) {
      // console.log("response", response.data);
      if (response.data && response.data.errors) {
        dispatch(setMessage(response.data.errors, "error"));
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data.access_token);
        localStorage.removeItem("otp_sent");
        localStorage.removeItem("otp_verified");
        dispatch(setMessage("Logged in successfully!"));
      }
      dispatch(hideLoading());
      return;
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch(hideLoading());
    }
  } catch (error) {
    console.error("error in signin user", error);
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch(setMessage(error.response.data.error, "error"));
    dispatch(hideLoading());
  }
};
export const authUser = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await auth();

    if (response.status === 200) {
      dispatch({
        type: AUTH_USER,
        payload: response.data,
      });

      dispatch(hideLoading());
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch(hideLoading());
    }
  } catch (error) {
    console.error("error in signin user", error);
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch(hideLoading());
  }
};

export const signupUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await signup(data);
    if (response.status === 200) {
      // dispatch({
      //   type: SIGNUP_SUCCESS,
      //   payload: response.data,
      // });
      // localStorage.setItem("user", JSON.stringify(response.data));
      // localStorage.setItem("token", response.data.access_token);
      dispatch(hideLoading());
      dispatch(
        setMessage(
          "OTP sent to your email, please check and verify!",
          "success"
        )
      );
      localStorage.setItem("otp_sent", true);
    } else {
      dispatch({
        type: SIGNUP_FAIL,
      });
      dispatch(hideLoading());
    }
  } catch (error) {
    console.error("error in signup user", error);
    dispatch({
      type: SIGNUP_FAIL,
    });
    dispatch(setMessage(error.response.data.error, "error"));
    dispatch(hideLoading());
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await logout();
    if (response.status === 200) {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      localStorage.clear();
      dispatch(hideLoading());
    } else {
      dispatch(hideLoading());
    }
  } catch (error) {
    console.error("error in logout user", error);

    dispatch(hideLoading());
  }
};

export const forgotPasswordUser = (data) => async (dispatch) => {
  try {
   // dispatch(showLoading());
    const response = await forgotPassword(data);
    console.log(response.status,response.data);
    if (response.status === 200) {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        // payload: response.data,
      });
      dispatch(setMessage(response.data, "success"));
      dispatch(hideLoading());
    } else {
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
      });
      dispatch(hideLoading());
    }
  } catch (error) {
    console.error("error in forgot password user", error);
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
    });
    dispatch(hideLoading());
    if (error.response && error.response.status === 404 ){
      throw Error("Email Id doesn't exist");
    }
    throw error.response.data.error || error.message;
  }
  
};

export const resetPasswordUser = (data) => async(dispatch) => {
  try {
    dispatch(showLoading());
    const response = await resetPassword(data);
    if(response.status===200) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: response.data
      });
      dispatch(hideLoading());
    } else {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: response.data
      })
      dispatch(hideLoading());
    }
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL
    });
    dispatch(hideLoading());
    throw error.response.data.error || error.message;
  }
};

export const verifyResetTokenUser = (data) => async(dispatch) => {
  try{
    dispatch(showLoading());
    const response = await verifyResetToken(data);
    if(response.status === 200) {
      dispatch({
        type: VERIFY_RESET_TOKEN_SUCCESS,
        payload: response.data
      })
    }
    dispatch(hideLoading());
  } catch {
    dispatch({
      type: VERIFY_RESET_TOKEN_FAIL
    })
    dispatch(hideLoading());
  }
};

export const verifyOtp = (data) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await _verifyOtp(data);
    if (response.status === 200) {
      dispatch({
        type: VERIFY_OTP_SUCCESS,
      });
      dispatch(setMessage("OTP verified successfully!"), "success");
      localStorage.setItem("otp_verified", true);
    }
    dispatch(hideLoading());
  } catch (error) {
    // console.error("error in logout user", error);
    dispatch(setMessage(error.response.data.error, "error"));

    dispatch(hideLoading());
  }
};
export const Userprofile = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await getuserprofile();
    if (response !== 200) {
      dispatch({
        type: USER_PROFILE,
        payload: response,
      });
      dispatch(hideLoading());
    } else {
      // dispatch({
      //   type: LOGIN_FAIL,
      // });
      dispatch(hideLoading());
    }
  } catch (error) {
    console.error("error in getting user profile", error);
    // dispatch({
    //   type: LOGIN_FAIL,
    // });
    dispatch(hideLoading());
  }
};

export const changePassword = (obj) => async (dispatch) => {
  try {
    let res = await changeuserPassword(obj);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_USER_PASSWORD,
        payload: res,
      });
    } else {
      dispatch(setMessage(res.status, "error"));
      throw Error("Some Error Occurred");
    }
  } catch (error) {
    // dispatch(setMessage(error, "error"));
    throw error.message || error;
  }
};

export const updateUserProfileDetails = (obj) => async (dispatch) => {
  try {
    
    let res = await updateUserDetails(obj);
    console.log("auth.js", res);
    if(res.status === 200) {
      dispatch({
        type: UPDATE_USER_DETAILS,
        payload: res,
      });
      dispatch(setMessage("Profile Updated!", "success"));
    } else {
      dispatch(setMessage("error: "+res.status, "error"));
    }
  } catch (error) {
    dispatch(setMessage(error.data.error, "error"));
  }
}

export const uploadPicture = (obj) => async (dispatch) => {
  try {
    let res = await uploadProfilePicture(obj);
    if (res.status === 200) {
      dispatch({
        type: UPLOAD_USER_PROFILE,
        payload: res,
      });
    } else {
      dispatch(setMessage(res.status, "error"));
    }
  } catch (error) {
    dispatch(setMessage(error, "error"));
  }
};
