import {
  GET_SKILLS_SUCCESS,
  GET_SELF_EVAL_SKILLS_SUCCESS,
  GET_SELF_EVAL_TEST_SUCCESS,
  SUBMIT_MCQ_TEST_SUCCESS,
  SUBMIT_AI_INTERVIEW_ANSWER_SUCCESS,
  SUBMIT_AI_INTERVIEW_ANSWER_FAIL,
  UPLAOD_AI_CV_SUCCESS,
  UPLAOD_AI_CV_FAIL,
  CLEAR_DATA_REDUCER,
  TOGGLE_THEME,
  GET_AI_SCORE_REPORT,
  GET_COURSE_SUGGESTIONS,
  SUBMIT_CODE_TEST_SUCCESS,
} from "../actions/types";

const initialState = {
  skills: null,
  selfSkills: null,
  test: null,
  testResult: null,
  uploadCVRes: null,
  submitAnsRes: null,
  currentTheme : "light",
  courses: null,
  testResultCode : null
};

const dashboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SUBMIT_CODE_TEST_SUCCESS:
      return {
        ...state,
        testResultCode: payload
      }

    case TOGGLE_THEME:
      return {
        ...state,
        currentTheme: payload,
      };
    case CLEAR_DATA_REDUCER:
      return {
        ...state,
        testResult: null,
        uploadCVRes: null,
        submitAnsRes: null,
        test: null,
      };
    case GET_SKILLS_SUCCESS:
      return {
        ...state,
        skills: payload.rows,
      };
    case UPLAOD_AI_CV_SUCCESS:
      return {
        ...state,
        uploadCVRes: payload,
      };
    case SUBMIT_AI_INTERVIEW_ANSWER_SUCCESS:
      return {
        ...state,
        submitAnsRes: payload,
      };
    case SUBMIT_MCQ_TEST_SUCCESS:
      return {
        ...state,
        testResult: payload,
      };
    case GET_SELF_EVAL_SKILLS_SUCCESS:
      return {
        ...state,
        selfSkills: payload,
      };
    case GET_SELF_EVAL_TEST_SUCCESS:
      return {
        ...state,
        test: payload,
      };
    case GET_AI_SCORE_REPORT:
      return {
        ...state,
        aiScore: payload
      };
    case GET_COURSE_SUGGESTIONS:
      return {
        ...state,
        courses: payload
      }
    default:
      return state;
  }
};
export default dashboard;
