import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { connect } from "react-redux";
import { verifyOtp } from "../../redux/actions/auth";
import { useSearchParams, useNavigate } from "react-router-dom";
import Copyright from "../../components/Copyright";
import Logo from "../../components/Logo";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const VerifyOtp = ({ verifyOtp }) => {
  const [otp, setOtp] = useState(null);
  const [email, setEmail] = useState("");
  const [u_id, setU_id] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const urlOTP = searchParams.get("otp");
  const urlUID = searchParams.get("u_id");

  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("otp_verified")) {
      navigate("/signin");
    }
  }, [localStorage.getItem("otp_verified")]);

  useEffect(() => {
    if (urlOTP && urlUID) {
      setOtp(urlOTP);
      setU_id(urlUID);
    }
  }, [urlOTP, urlUID]);

  const onChangeOtp = (e) => {
    setOtp(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const captchaToken = await executeRecaptcha("verify_otp");
    let data = {
      email,
      otp,
      u_id,
      reCaptchaToken: captchaToken,
    };
    // verifyOTP({ email, otp });

    console.log("Data: ", data);
    await verifyOtp(data);
  };

  return (
    <Grid container item xs={12} sm={12}>
      <Grid
        item
        xs={6}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // alignContent: "center",
          minHeight: "100vh",
          justifyContent: "center",
          background: "white",
        }}
      >
        <Logo />
        <Typography  variant="h5" sx={{ mt: 2 }}>
          Verify OTP
        </Typography>

        <FormControl
          variant="outlined"
          sx={{ mt: 2, width: 350, height: 50 }}
          required
        >
          <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>

          <OutlinedInput
            autoComplete="email"
            name="email"
            label="email"
            autoFocus
            focused
            value={email}
            onChange={onChangeEmail}
          />
        </FormControl>

        <FormControl
          variant="outlined"
          sx={{ mt: 2, width: 350, height: 50 }}
          required
        >
          <InputLabel htmlFor="outlined-adornment-password">OTP</InputLabel>

          <OutlinedInput
            type="number"
            required
            label="OTP"
            name="otp"
            value={otp}
            onChange={onChangeOtp}
          />
        </FormControl>

        <Button
          onClick={onSubmit}
          variant="contained"
          sx={{ mt: 4, mb: 2 }}
          disabled={!email || !otp}
        >
          Verify OTP
        </Button>

        <Copyright sx={{ mt: 5 }} />
      </Grid>
      <Grid item xs={6} sm={6}>
        <div className="container-fluid homepage-bgimage"></div>
      </Grid>
    </Grid>
  );
};

const VerifyOtpWithReCaptcha = ({ verifyOtp }) => {
  const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_API_KEY;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <VerifyOtp verifyOtp={verifyOtp} />
    </GoogleReCaptchaProvider>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { verifyOtp };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyOtpWithReCaptcha);
