import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Typography
} from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SendInviteDialog = ({
  open,
  handleClose,
  confirmAndSendInvite,
  openConfirmationDialog,
  closeConfirmationDialog,
  mcqChecked,
  setMcqChecked,
  codeChecked,
  setCodeChecked,
  aiAssistanceCheck,
  setAiAssistanceCheck,
  emailContent,
  setEmailContent,
  mcqCount,
  handleMcqCountChange,
  mcqError,
  codeCount,
  handleCodeCountChange,
  codeError,
  mcqTime,
  handleMcqTimeChange,
  codeTime,
  handleCodeTimeChange,
  aiInterviewTime,
  handleAiInterviewTimeChange,
  showConfirmationDialog
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: "white"
          },
        }}
      >
        <DialogTitle>Send Invitation</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mcqChecked}
                    onChange={(e) => setMcqChecked(e.target.checked)}
                    color="primary"
                  />
                }
                label="MCQ"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={codeChecked}
                    onChange={(e) => setCodeChecked(e.target.checked)}
                    color="primary"
                  />
                }
                label="Code"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={aiAssistanceCheck}
                    onChange={(e) => setAiAssistanceCheck(e.target.checked)}
                    color="primary"
                    disabled={aiAssistanceCheck}
                  />
                }
                label="AI Interview"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            {mcqChecked && (
              <Grid item xs={4}>
                <TextField
                  label="MCQ Count"
                  type="number"
                  value={mcqCount}
                  onChange={handleMcqCountChange}
                  error={mcqError}
                  helperText={mcqError ? "MCQs must be in range of 10-100" : ""}
                  variant="standard"
                />
              </Grid>
            )}
            {codeChecked && (
              <Grid item xs={4}>
                <TextField
                  label="Code Count"
                  type="number"
                  value={codeCount}
                  onChange={handleCodeCountChange}
                  error={codeError}
                  helperText={codeError ? "Coding Questions must be in range of 2-10" : ""}
                  variant="standard"
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            {mcqChecked && (
              <Grid item xs={4}>
                <TextField
                  label="MCQ Time (minutes)"
                  type="number"
                  value={mcqTime}
                  onChange={handleMcqTimeChange}
                  variant="standard"
                />
              </Grid>
            )}
            {codeChecked && (
              <Grid item xs={4}>
                <TextField
                  label="Code Time (minutes)"
                  type="number"
                  value={codeTime}
                  onChange={handleCodeTimeChange}
                  variant="standard"
                />
              </Grid>
            )}
            {aiAssistanceCheck && (
              <Grid item xs={4}>
                <TextField
                  label="AI Interview Time (minutes)"
                  type="number"
                  value={aiInterviewTime}
                  onChange={handleAiInterviewTimeChange}
                  variant="standard"
                />
              </Grid>
            )}
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">Email Template:</Typography>
            <ReactQuill
              theme="snow"
              value={emailContent}
              onChange={setEmailContent}
              style={{ height: '260px', marginBottom: '50px' }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={openConfirmationDialog}
            color="primary"
            disabled={(!mcqChecked && !codeChecked && !aiAssistanceCheck) || mcqError || codeError}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmationDialog}
        onClose={closeConfirmationDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to send this invitation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog}>Cancel</Button>
          <Button onClick={confirmAndSendInvite} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendInviteDialog;
